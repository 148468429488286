<template>
  <div>
    <!--1. No displayed accounts-->
    <div
      v-if="!hasAvailableAccounts && !isLoading && !hasConsent"
      class="h-32 p-8 space-y-3"
    >
      <p class="text-sm text-bb-text-default">Sign in to Google to sync with Google Ads</p>
      <GoogleAllowAccessButton
        white
        name="Sign in with google"
        :access-scope="googleConfig.scopes.connectStore"
        @verify-token="verifyToken"
      >
        <span class="text-sm">Sign in with Google</span>
      </GoogleAllowAccessButton>
    </div>
    <!--2. Loading-->
    <div
      v-if="isLoading"
      class="h-32 w-full flex items-center justify-center"
    >
      <p>LOADING...</p>
    </div>

    <!--3. Display a list of Google Accounts-->
    <div
      v-if="hasAvailableAccounts && !isLoading && hasConsent"
      class="py-4 px-6 space-y-6"
    >
      <div class="text-sm">
        <div v-if="accountsList.length > 0">
          <p>Accounts connected to your Google account:</p>
          <text-input
            v-model="searchQuery"
            :disabled="isLoading"
            input-name="search-query"
            input-id="search-query"
            input-type="text"
            placeholder="Search for an account"
          />
        </div>
        <p v-else>No accounts found</p>
      </div>
      <div class="text-sm border border-neutral-100 divide-y divide-neutral-100">
        <div
          v-for="(googleAdsStore, index) in paginationRows"
          :key="index"
          class="flex justify-between items-center px-6 h-16"
        >
          <p>
            {{ googleAdsStore.name }}&ensp;<span class="font-bold">{{ googleAdsStore.readable_id }}</span>
          </p>
          <MergeButtonSquare
            v-if="
              connectedAccount.readable_id !== googleAdsStore.readable_id &&
              selectedAccount.readable_id !== googleAdsStore.readable_id
            "
            button-type="google"
            @click="selectGoogleAdsStore(googleAdsStore)"
          >
            Connect
          </MergeButtonSquare>
          <div
            v-else
            class="bg-bb-success-bg text-bb-success-text rounded-full py-1 px-3 m-3"
          >
            {{ selectedAccount.readable_id === googleAdsStore.readable_id ? 'Selected' : 'Connected' }}
          </div>
        </div>

        <div
          v-if="searchQuery && totalItems <= 0"
          class="flex gap-2 items-center px-12 h-16 bg-neutral-0 cursor-pointer"
        >
          <p class="font-medium">No accounts found</p>
        </div>
        <div class="flex gap-2 items-center px-12 h-16 bg-neutral-0 cursor-pointer">
          <ic-plus
            class="text-bb-brand-purple"
            size="16"
          />
          <p class="font-medium text-bb-brand-purple">Create a new Google Ads account</p>
        </div>
      </div>
      <div v-if="rowsPerPage < accountsList.length">
        <pagination
          :limit="rowsPerPage"
          :page="currentPage"
          :max="totalItems"
          @onChange="changePage"
        />
      </div>

      <p
        v-if="!connectedAccount.id"
        class="text-sm"
      >
        Cant’s find your Google Ads account? You might need to log in with a different Google account
      </p>
      <p
        v-else
        class="text-sm"
      >
        Would you prefer selecting a different account?
      </p>
      <GoogleAllowAccessButton
        white
        name="Connect to another Google account"
        :access-scope="googleConfig.scopes.connectStore"
        @verify-token="verifyToken"
      >
        <span class="text-sm">Connect to another Google account</span>
      </GoogleAllowAccessButton>
    </div>
  </div>
</template>

<script>
import IcPlus from '@/components/icon/ic-plus'
import Pagination from '@/components/table/Pagination'
import googleConfig from '@/configs/google-client'
import { mapGetters } from 'vuex'
import GoogleAllowAccessButton from '@/components/btn/Google/GoogleAllowAccessButton.vue'
import TextInput from '@/components/input/brightbid/TextInput.vue'
import Toast from '@/components/shared/Toast.vue'

export default {
  name: 'GoogleAdsIntegration',
  components: {
    GoogleAllowAccessButton,
    IcPlus,
    Pagination,
    TextInput,
  },
  inject: ['context'],
  props: {
    applyChanges: {
      type: Boolean,
      default: false,
    },
    isOnboarding: {
      type: Boolean,
      default: false,
    },
    siteDetails: {
      type: Object,
      default: function () {
        return {
          organizationID: '',
          siteID: '',
        }
      },
    },
  },
  data() {
    return {
      // pagination
      currentPage: 1,
      rowsPerPage: 5,
      searchQuery: '',
      totalItems: 0,
      isLoading: false,
      accountsList: [],
      connectedAccount: {},
      hasAvailableAccounts: false,
      hasConsent: false,
      organizationID: this.isOnboarding ? this.context.organizationID : this.siteDetails.organizationID,
      siteID: this.isOnboarding ? this.context.siteID : this.siteDetails.siteID,
      googleConfig: googleConfig,
      selectedAccount: {},
    }
  },
  computed: {
    ...mapGetters({
      account: 'account/getAccount',
      user: 'auth/user',
    }),
    paginationRows() {
      if (!this.accountsList) return []

      let filteredAccounts = this.accountsList

      if (this.searchQuery) {
        const searchQueryLower = this.searchQuery.toLowerCase()
        filteredAccounts = this.accountsList.filter(
          account =>
            (account.name && account.name.toLowerCase().includes(searchQueryLower)) ||
            (account.readable_id && account.readable_id.toLowerCase().includes(searchQueryLower)),
        )
      }
      this.updatePagination(filteredAccounts.length)
      return filteredAccounts.slice((this.currentPage - 1) * this.rowsPerPage, this.currentPage * this.rowsPerPage)
    },
  },
  watch: {
    searchQuery() {
      this.currentPage = 1 // Reset to page 1 on new search
    },
    applyChanges(newVal) {
      //This function is used only in managing channels. If the new value of 'applyChanges' is true then proceed with connecting the account
      if (newVal) {
        const googleAdsStore = this.selectedAccount
        if (Object.keys(googleAdsStore).length != 0) {
          this.$emit('toggle-modal-loading', true)
          this.connectGoogleAdsStore(googleAdsStore)
        } else {
          this.$toast.warning({
            component: Toast,
            props: {
              title: 'Select Account',
              message: `Please select an account to continue.`,
              type: 'info',
            },
          })
          this.$emit('change-applied', 'failed')
        }
      }
    },
  },
  async mounted() {
    this.isLoading = true
    // this.hasConsent = (
    //   await this.$http.get(`common/organization/${this.organizationID}/integration/google/ads/check-consent`)
    // ).data
    // if (!this.hasConsent) {
    //   this.isLoading = false
    //   this.hasConsent = false
    //   return
    // }

    const availableGoogleStore = await this.loadGoogleAvailableStores(this.siteID, null)
    if (availableGoogleStore) {
      await this.loadConnectedGoogleAdsAccount()
      this.initializeAccountList()
    }
    this.isLoading = false
  },
  methods: {
    initializeAccountList() {
      if (this.accountsList.length > 0) {
        this.hasAvailableAccounts = true
        this.currentPage = 1
        this.filterAccounts()
      }
    },
    changePage(paginationData) {
      this.currentPage = paginationData.current_page
      this.filterAccounts()
    },
    filterAccounts() {
      this.updatePagination(this.accountsList.length)
    },
    updatePagination(totalItems) {
      this.totalItems = totalItems
    },
    async verifyToken(response) {
      try {
        this.isLoading = true
        let organizationIntegration = await this.$http.post(
          `common/organization/${this.organizationID}/integration/google/ads/grant`,
          {
            api_token: response.code,
          },
        )
        this.hasConsent = true
        await this.loadGoogleAvailableStores(this.siteID, organizationIntegration.data.id)
        this.$emit('integration-finished')
        this.disabled = false
        return true
      } catch (error) {
        this.hasAvailableAccounts = false
        this.isLoading = false
        console.log(error)
      }
    },
    async loadGoogleAvailableStores(siteId, organizationIntegrationId) {
      this.isLoading = true
      try {
        let config = {
          params: {},
        }
        if (siteId) {
          config.params['site_id'] = siteId
        }
        if (organizationIntegrationId) {
          config.params['organization_integration_id'] = organizationIntegrationId
        }

        const resp = await this.$http.get(`common/organization/${this.organizationID}/integration/google/ads`, config)
        this.accountsList = resp.data.filter(item => !item.manager)
        this.initializeAccountList()
      } catch (error) {
        this.hasAvailableAccounts = false
        console.log(error)
        this.isLoading = false
        return false
      }
      this.isLoading = false
      return true
    },
    async loadConnectedGoogleAdsAccount() {
      this.isLoading = true
      try {
        const resp = await this.$http.get(`common/site/${this.siteID}/integration/google/ads`)
        this.connectedAccount = this.accountsList.find(
          item => item.id === resp.data.site_integration_data.google_ads_customer_id,
        )
      } catch (error) {
        this.hasAvailableAccounts = false
        console.log(error)
        this.isLoading = false
        return false
      }
      this.isLoading = false
      return true
    },
    async connectGoogleAdsStore(googleAdsStore) {
      this.isLoading = true
      try {
        await this.$http
          .post(`common/site/${this.siteID}/integration/google/ads`, {
            google_ads_customer_id: googleAdsStore.id,
          })
          .then(resp => {
            if (resp && resp.status === 200) {
              this.connectedAccount = googleAdsStore
              if (this.$attrs.connectedToStore) {
                this.$gtm.trackEvent({
                  event: 'connect_store_success',
                })
              }
              localStorage.setItem(googleAdsStore.id, googleAdsStore.id)

              if (!this.isOnboarding) this.$emit('change-applied', 'success')
              this.$toast.success({
                component: Toast,
                props: {
                  title: 'Success',
                  message: `Changes applied.`,
                  type: 'success',
                },
              })
            } else {
              throw new Error()
            }
          })
          .finally(() => {
            if (!this.isOnboarding) this.$emit('toggle-modal-loading', false)
          })
        this.$emit('update')
        this.isLoading = false
      } catch (error) {
        this.isLoading = false
        console.log(error)
        if (!this.isOnboarding) this.$emit('change-applied', 'failed')
        this.$toast.warning({
          component: Toast,
          props: {
            title: 'Failed',
            message: `Failed saving changes. Please try again or contact support.`,
            type: 'warning',
          },
        })
      }
    },
    selectGoogleAdsStore(googleAdsStore) {
      if (this.isOnboarding) {
        this.connectGoogleAdsStore(googleAdsStore)
      } else {
        this.selectedAccount = googleAdsStore
      }
    },
  },
}
</script>

<style scoped></style>
