<template>
  <div class="w-full h-full flex items-center justify-center">
    <div class="welcome-container text-center w-full space-y-4 pb-40">
      <img
        src="/content/images/brightbid/colored-brightbid-logo-only.svg"
        class="onboarding-rectangles mx-auto"
        alt="Brightbid"
      />
      <h3>Hi {{ userName }} 👋</h3>
      <h1 class="h1a">Welcome to BrightBid</h1>
      <div class="text-bb-text-secondary pb-6">
        <p>We're excited to help you get the most out of your Google Ads account. With</p>
        <p>BrightBid, you'll be able to easily manage your campaigns, track your success and gain</p>
        <p>insights to help you grow your business.</p>
      </div>
      <MergeButtonRound
        brightbid
        class="w-40"
        @click="handleClick"
        >Let’s Get Started</MergeButtonRound
      >
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'WelcomeStep',
  computed: {
    ...mapGetters({
      userName: 'auth/getUserName',
    }),
    ...mapState('auth', ['user']),
  },
  methods: {
    handleClick() {
      this.$emit('next')
      // if (this.user.role.name != 'standard') {
      //   this.$emit('next')
      // }
      // this.$router.push({ name: 'portfolio' })
    },
  },
}
</script>

<style scoped>
.welcome-container {
  width: 635px;
}
</style>
