<template>
  <div class="wizard-container bg-white w-full overflow-auto">
    <div
      v-if="navigatedToSpecificStep"
      class="fixed py-8 pl-10"
    >
      <div
        class="flex flex-row text-bb-text-default gap-2 cursor-pointer"
        @click="$router.back()"
      >
        <ic-chevron
          :size="16"
          class="my-auto"
        />
        <p class="p1">Back</p>
      </div>
    </div>
    <transition
      name="page-fade"
      mode="out-in"
    >
      <welcome
        v-if="onboardingWizardState.matches(STATES.WELCOME)"
        @next="sendEvent(EVENTS.NEXT)"
      />
      <view-or-create-organization
        v-if="onboardingWizardState.matches(STATES.VIEW_OR_CREATE_ORGS)"
        :context="onboardingWizardState.context"
        @next="payload => sendEvent({ type: EVENTS.NEXT, payload })"
        @prev="sendEvent(EVENTS.PREV)"
      />
      <setup-connections
        v-if="onboardingWizardState.matches(STATES.SETUP_CONNECTIONS)"
        :context="onboardingWizardState.context"
        @next="sendEvent(EVENTS.NEXT)"
        @previous="sendEvent(EVENTS.PREV)"
      />
      <account-setup
        v-if="onboardingWizardState.matches(STATES.ACCOUNT_SETUP)"
        :context="onboardingWizardState.context"
        @next="sendEvent(EVENTS.NEXT)"
        @prev="sendEvent(EVENTS.PREV)"
      />

      <setup-business-details
        v-if="onboardingWizardState.matches(STATES.SETUP_BUSINESS_DETAILS)"
        :context="onboardingWizardState.context"
        @next="sendEvent(EVENTS.NEXT)"
        @prev="sendEvent(EVENTS.PREV)"
      />

      <setup-account-goals
        v-if="onboardingWizardState.matches(STATES.SETUP_ACCOUNT_GOALS)"
        :context="onboardingWizardState.context"
        @next="sendEvent(EVENTS.NEXT)"
        @prev="sendEvent(EVENTS.PREV)"
      />

      <exit-onboarding
        v-if="onboardingWizardState.matches(STATES.EXIT_ONBOARDING)"
        :context="onboardingWizardState.context"
        @next="sendEvent(EVENTS.NEXT)"
        @prev="sendEvent(EVENTS.PREV)"
        @add="addAnotherAccount"
      />

      <loading-page
        v-if="onboardingWizardState.matches(STATES.LOADING_PAGE)"
        :context="onboardingWizardState.context"
        @next="sendEvent(EVENTS.NEXT)"
        @finish="payload => finishOnboarding(payload)"
        @prev="sendEvent(EVENTS.PREV)"
      />
    </transition>
  </div>
</template>

<script>
import { interpret, State } from 'xstate'

import onboardingWizardMachine, {
  EVENTS,
  ONBOARDING_WIZARD_STATE_KEY,
  STATES,
} from '@/core/machines/onboarding-wizard-v2'
import Welcome from '@/views/onboarding_v2/Welcome'
import ViewOrCreateOrganization from '@/views/onboarding_v2/ViewOrCreateOrganization'
import SetupConnections from '@/views/onboarding_v2/SetupConnections'
import AccountSetup from '@/views/onboarding_v2/AccountSetup'
import SetupBusinessDetails from '@/views/onboarding_v2/SetupBusinessDetails'
import SetupAccountGoals from '@/views/onboarding_v2/SetupAccountGoals'
import ExitOnboarding from '@/views/onboarding_v2/ExitOnboarding'
import LoadingPage from '@/views/onboarding_v2/LoadingPage'
import IcChevron from '@/components/icon/ic-chevron'
import { mapActions } from 'vuex'

const stateDefinition =
  JSON.parse(localStorage.getItem(ONBOARDING_WIZARD_STATE_KEY)) || onboardingWizardMachine.initialState

// "desiredState" is set when accessing the onboarding from a different page

// If the onboarding is accessed without desiredState,
// the process will start either from the beginning or resume from the last recorded state
if (stateDefinition.context.desiredState) {
  stateDefinition.context = onboardingWizardMachine.initialState.context
  stateDefinition.value = STATES.WELCOME
}

const startingState = State.create(stateDefinition)

export default {
  name: 'OnboardingWizard',
  components: {
    Welcome,
    ViewOrCreateOrganization,
    SetupConnections,
    AccountSetup,
    SetupBusinessDetails,
    SetupAccountGoals,
    ExitOnboarding,
    LoadingPage,
    IcChevron,
  },
  data() {
    return {
      onboardingWizardService: interpret(onboardingWizardMachine).start(startingState),
      onboardingWizardState: onboardingWizardMachine.initialState,
      STATES,
      EVENTS,
      navigatedToSpecificStep: false,
    }
  },
  created() {
    const { desiredState, organizationId, organizationName } = this.$route.query
    const initialContext = { ...onboardingWizardMachine.context }
    let STARTING_STATE = startingState.value
    let STARTING_CONTEXT = startingState.context

    if (desiredState) {
      this.navigatedToSpecificStep = true
      STARTING_STATE = STATES[desiredState]
      initialContext.desiredState = desiredState

      // Only set the organizationId if it exists to work around adding a new organization
      if (organizationId) {
        initialContext.organizationID = organizationId
      }

      // Set organization name to properly assign a selected site
      if (organizationName) {
        initialContext.organizationName = organizationName
      }

      STARTING_CONTEXT = initialContext
    }

    this.onboardingWizardService = interpret(onboardingWizardMachine.withContext(initialContext))
      .onTransition(state => {
        this.onboardingWizardState = state
        localStorage.setItem(ONBOARDING_WIZARD_STATE_KEY, JSON.stringify(state))
      })
      .start(State.from(STARTING_STATE, STARTING_CONTEXT))
  },
  methods: {
    ...mapActions({
      loadSelectedSite: 'site/loadSelectedSite',
    }),
    sendEvent(event) {
      this.onboardingWizardService.send(event)
    },
    async finishOnboarding(payload) {
      await this.loadSelectedSite({
        label: payload?.siteName,
        value: payload?.siteID,
        url: payload?.siteURL,
        site_integration: null,
        site_organization_name: payload?.organizationName,
        site_organization_id: payload?.organizationID,
      })
      localStorage.removeItem(ONBOARDING_WIZARD_STATE_KEY)
      this.$router.push({ name: 'dashboard' })
    },
    addAnotherAccount() {
      localStorage.removeItem(ONBOARDING_WIZARD_STATE_KEY)
      this.$router.push({ query: { ...this.$route.query, desiredState: 'VIEW_OR_CREATE_ORGS' } })
    },
  },
}
</script>

<style scoped lang="scss">
.wizard-container {
  height: calc(100% - 8px);
}
</style>
