import { assign, createMachine } from 'xstate'

export const STATES = {
  WELCOME: 'WELCOME',
  VIEW_OR_CREATE_ORGS: 'VIEW_OR_CREATE_ORGS',
  SETUP_CONNECTIONS: 'SETUP_CONNECTIONS',
  ACCOUNT_SETUP: 'ACCOUNT_SETUP',
  SETUP_BUSINESS_DETAILS: 'SETUP_BUSINESS_DETAILS',
  SETUP_ACCOUNT_GOALS: 'SETUP_ACCOUNT_GOALS',
  EXIT_ONBOARDING: 'EXIT_ONBOARDING',
  LOADING_PAGE: 'LOADING_PAGE',
}

export const EVENTS = {
  NEXT: 'NEXT',
  PREV: 'PREV',
}

export const ONBOARDING_WIZARD_STATE_KEY = 'onboarding-wizard-state-key'

// State machine
const onboardingWizardMachine = createMachine(
  {
    id: 'onboarding',
    initial: STATES.WELCOME,
    context: {
      organizationName: null,
      siteURL: null,
      siteName: null,
      siteCountry: null,
      siteLanguage: null,
      isWebsite: null,
      target: null,
      businessDescription: null,
      industry: null,
      organizationID: null,
      siteID: null,
      desiredState: null,
    },
    states: {
      [STATES.WELCOME]: {
        on: { [EVENTS.NEXT]: STATES.VIEW_OR_CREATE_ORGS },
      },
      [STATES.VIEW_OR_CREATE_ORGS]: {
        on: { [EVENTS.NEXT]: STATES.SETUP_CONNECTIONS },
      },
      [STATES.SETUP_CONNECTIONS]: {
        on: {
          [EVENTS.PREV]: [STATES.VIEW_OR_CREATE_ORGS],
          [EVENTS.NEXT]: [STATES.ACCOUNT_SETUP],
        },
      },
      [STATES.ACCOUNT_SETUP]: {
        on: {
          [EVENTS.PREV]: STATES.SETUP_CONNECTIONS,
          [EVENTS.NEXT]: STATES.SETUP_BUSINESS_DETAILS,
        },
      },
      [STATES.SETUP_BUSINESS_DETAILS]: {
        on: {
          [EVENTS.PREV]: STATES.ACCOUNT_SETUP,
          [EVENTS.NEXT]: STATES.SETUP_ACCOUNT_GOALS,
        },
      },
      [STATES.SETUP_ACCOUNT_GOALS]: {
        on: {
          [EVENTS.PREV]: STATES.SETUP_BUSINESS_DETAILS,
          [EVENTS.NEXT]: STATES.EXIT_ONBOARDING,
        },
      },
      [STATES.EXIT_ONBOARDING]: {
        on: {
          [EVENTS.PREV]: STATES.SETUP_ACCOUNT_GOALS,
          [EVENTS.NEXT]: STATES.LOADING_PAGE,
        },
      },
      [STATES.LOADING_PAGE]: {
        on: {
          [EVENTS.PREV]: STATES.EXIT_ONBOARDING,
        },
      },
    },
  },
  {
    actions: {
      assignOrganizationName: assign({
        organizationName(context, event) {
          if (event.type === 'PREV' || !event.payload) {
            return context.organizationName || null
          }
          return event.payload.name
        },
        organizationID(context, event) {
          if (!event.payload) return context.organizationID
          return event.payload.organizationID
        },
      }),
      assignSiteDetails: assign({
        siteName(context, event) {
          if (!event.payload) return context.siteName
          return event.payload.siteName
        },
        siteCountry(context, event) {
          if (!event.payload) return context.siteCountry
          return event.payload.siteCountry
        },
        siteLanguage(context, event) {
          if (!event.payload) return context.siteLanguage
          return event.payload.siteLanguage
        },
        siteURL(context, event) {
          if (!event.payload) return context.siteURL
          return event.payload.siteUrl
        },
      }),
      assignSiteSetup: assign({
        target(context, event) {
          // default to B2B
          if (!event.payload) return ['B2B']
          return event.payload.target
        },
        businessDescription(context, event) {
          if (!event.payload) return context.businessDescription
          return event.payload.businessDescription
        },
        industry(context, event) {
          if (!event.payload) return context.industry
          return event.payload.industry
        },
        siteID(context, event) {
          if (!event.payload) return context.siteID
          return event.payload.siteID
        },
      }),
    },
  },
)

export default onboardingWizardMachine
