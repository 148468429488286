<template>
  <div class="w-full h-full flex items-center justify-center">
    <div class="welcome-container text-center w-full space-y-4 pb-40">
      <img
        src="/content/images/brightbid/colored-brightbid-logo-only.svg"
        class="onboarding-rectangles mx-auto"
        alt="Brightbid"
      />
      <h1 class="h1a">All done!</h1>
      <h1>Do you want to add another account?</h1>
      <div class="text-bb-text-secondary pb-6">
        <p>Do you want to set up more accounts?</p>
      </div>
      <div>
        <MergeButtonRound
          button-type="secondary"
          brightbid
          class="w-60"
          @click="handleClick('add')"
          >Yes, add one more account
        </MergeButtonRound>
      </div>

      <MergeButtonRound
        brightbid
        class="w-40"
        @click="handleClick('next')"
        >No, get started
      </MergeButtonRound>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'ExitOnbaording',
  computed: {
    ...mapGetters({
      userName: 'auth/getUserName',
    }),
    ...mapState('auth', ['user']),
  },
  methods: {
    handleClick(action) {
      if (action === 'next') {
        this.$emit('next')
      }
      if (action === 'add') {
        this.$emit('add')
      }
    },
  },
}
</script>

<style scoped>
.welcome-container {
  width: 635px;
}
</style>
