<template>
  <div class="w-full h-full flex items-center justify-center">
    <div class="text-center w-full space-y-2 pb-40">
      <img
        src="/content/images/brightbid/colored-brightbid-logo-only.svg"
        class="mx-auto"
        alt="Brightbid"
      />
      <h1 class="text-2xl text-bb-text-default font-medium">What's your next move?</h1>
      <div class="text-sm text-bb-neutral-gray pb-16">
        <p>What type of organization you manage?</p>
      </div>
      <div class="mx-auto max-w-sm w-full flex flex-col gap-6">
        <OrganizationButton @next="next">
          <template #text="slotProps">
            <p
              class="text-sm font-medium text-bb-text-headers"
              :class="[slotProps.isActive ? 'text-white' : 'text-black', 'font-medium']"
            >
              Create an Account
            </p>
          </template>
          <template #left-icon>
            <ic-plus :size="40" />
          </template>
        </OrganizationButton>
        <OrganizationButton
          v-if="userOrganizationLength > 0"
          @next="viewPortfolio"
        >
          <template #left-icon>
            <ic-skyscraper :size="40" />
          </template>
          <template #text="slotProps">
            <p
              class="text-sm font-medium text-bb-text-headers"
              :class="[slotProps.isActive ? 'text-white' : 'text-black', 'font-medium']"
            >
              Go to your accounts
            </p>
            <p class="p1">Total: {{ userOrganizationLength }} Accounts</p>
          </template>
          <template #right-icon>
            <ic-arrow
              :size="40"
              class="transform -rotate-90"
            />
          </template>
        </OrganizationButton>
      </div>
    </div>
  </div>
</template>
<script>
import IcPlus from '@/components/icon/ic-plus'
import IcArrow from '@/components/icon/ic-arrow'
import IcSkyscraper from '@/components/icon/ic-skyscraper'
import OrganizationButton from '@/components/onboard-components/OrganizationButton.vue'
import { mapState, mapActions } from 'vuex'

export default {
  name: 'ViewOrCreateOrgsStep',
  components: {
    IcPlus,
    IcSkyscraper,
    IcArrow,
    OrganizationButton,
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapState('organization', ['userOrganization']),
    userOrganizationLength() {
      if (!this.userOrganization) return 0
      return this.userOrganization.length
    },
  },
  async mounted() {
    if (this.user.id) {
      await this.loadUserOrganizationList(this.user.id)
    }
  },
  methods: {
    ...mapActions({
      loadUserOrganizationList: 'organization/loadUserOrganizationList',
    }),
    next() {
      this.$emit('next')
    },
    viewPortfolio() {
      this.$router.push({ name: 'portfolio' })
    },
  },
}
</script>
